import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";
import { ShowAlert } from "../../../../../../GlobalComponents/ShowAlert/ShowAlert";
import { b2cUserSendOtpAction } from "../../../Actions/B2CSubscription.action";
import { useDispatch } from "react-redux";
import { registerB2CUser } from "../../../../../../Redux/B2CSubscription-Slice/Subscription-slice";
import {
  validateEmail,
  validateMobileNumber,
} from "../../../Common/Regx.check.fn";
import { useNavigate } from "react-router-dom";

const Registration = ({ setStep }: any) => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (
      !validateEmail(userData.email) ||
      !validateMobileNumber(userData.mobile)
    )
      return ShowAlert(
        "warning",
        "Please enter valid email and 10-digit mobile no."
      );

    /**]
     * Name should of atleast more than 4 digit otherwise invalid name
     */
    if (userData.name.trim().length < 4)
      return ShowAlert("warning", "Please enter all details correctly.");

    try {
      setLoading(true);

      const payload = {
        countryCode: "91",
        email: userData.email,
        number: userData.mobile,
      };
      dispatch(registerB2CUser(userData));

      const res = await b2cUserSendOtpAction(payload);
      setLoading(false);

      // FOR VERIFYING THE OTP NAVIGATE TO OTP PAGE TO COMPLETE REG..
      if (res.data.success)
        navigate(`/registration/verify-otp/?mob=${userData?.mobile}`);

    } catch (error: any) {
      setLoading(false);
      ShowAlert(
        "warning",
        error?.response?.data?.message || "Please try again later.."
      );
    }
  };
  return (
    <Flex
      marginTop={isMobile ? "36px" : "0px"}
      width={isMobile ? "500px" : "584px"}
      height={isMobile ? "277.983px" : "522px"}
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(180deg, #FFF 0%, #D2F0F2 100%)"
    >
      <Flex
        width="auto"
        bg="white"
        height={isMobile ? "277.983px" : "450px"}
        boxShadow="xl"
        borderRadius="md"
        overflow="hidden"
      >
        {/* Right side with the form */}
        <Box flex="1.5" p="8" bg="white">
          <Heading
            mt={0}
            color="#333"
            fontSize={isMobile ? "9.586px" : "18px"}
            fontFamily="Poppins"
            fontWeight={isMobile ? "500" : "500"}
            lineHeight={isMobile ? "19.171px" : "36px"}
            letterSpacing="0.06em"
            fontStyle="normal"
            textAlign="center"
            as="h2"
            size="lg"
            mb={isMobile ? "2" : "2"}
          >
            Welcome
          </Heading>
          <Text
            mb={isMobile ? "3" : "4"}
            color="#333"
            fontSize={isMobile ? "6.39px" : "12px"}
            fontFamily="Poppins"
            fontWeight="400"
            lineHeight={isMobile ? "14.996px" : "36px"}
            letterSpacing="1.08px"
            fontStyle="normal"
            textAlign="center"
          >
            REGISTER AS A SUBSCRIBER
          </Text>
          <form>
            <Box mb={isMobile ? "5" : "8"} position={"relative"}>
              <Text
                position={"absolute"}
                bg="white"
                top={isMobile ? "-2" : "-3"}
                zIndex={10}
                left={"15px"}
                color="#424242"
                fontSize={isMobile ? "7px" : "10px"}
                fontFamily="Poppins"
                fontWeight="300"
                lineHeight={
                  isMobile ? "15px" : "22.53px"
                } 
                px="5px"
                fontStyle="normal"
              >
                Name
              </Text>
              <Input
                name="name"
                isRequired={true}
                maxH={isMobile ? "25.562" : "48px"}
                value={userData.name}
                onChange={handleChange}
                width={isMobile ? "full" : "488px"}
                fontSize={isMobile ? "10px" : "14px"}
                fontFamily="Poppins"
                fontWeight="400"
                bg="white"
                borderColor="gray.300"
              />
            </Box>
            <Box mb={isMobile ? "1" : "4"} position={"relative"}>
              <Text
                position={"absolute"}
                bg="white"
                top={isMobile ? "-2" : "-3"}
                zIndex={10}
                left={"15px"}
                color="#424242"
                fontSize={isMobile ? "7px" : "10px"}
                fontFamily="Poppins"
                fontWeight="300"
                px="5px"
                lineHeight={
                  isMobile ? "15px" : "22.53px"
                } /* 225.3% of 10px */
                fontStyle="normal"
              >
                Email
              </Text>
              <Input
                maxH={isMobile ? "25.562" : "48px"}
                name="email"
                value={userData.email}
                onChange={handleChange}
                fontSize={isMobile ? "10px" : "14px"}
                fontFamily="Poppins"
                fontWeight="400"
                type="email"
                mb="4"
                bg="white"
                borderColor="gray.300"
              />
            </Box>
            <Box mb="1" position={"relative"}>
              <Text
                position={"absolute"}
                bg="white"
                top={isMobile ? "-2" : "-3"}
                zIndex={10}
                left={"15px"}
                color="#424242"
                fontSize={isMobile ? "7px" : "10px"}
                fontFamily="Poppins"
                fontWeight="300"
                lineHeight={
                  isMobile ? "15px" : "22.53px"
                } 
                fontStyle="normal"
                px="5px"
              >
                Contact Number
              </Text>
              <InputGroup maxH={ isMobile ? "25.562px" : "48px"}>
                <InputLeftElement pointerEvents="none" maxH={ isMobile ? "25.562px" : "48px"}>
                  <Image
                    src="Images/indian_flag.png" 
                    alt="Indian Flag"  
                    width={isMobile ? "13.31px":"25px"}
                    height={isMobile ? "10.65px":"20px"}
                  />
                </InputLeftElement>
                <InputLeftElement pointerEvents="none" maxH={ isMobile ? "25.562px" : "48px"}>
                  <Text 
                  marginLeft={isMobile ? "40px":"60px"} 
                  pt={"1px"}
                  fontSize={isMobile ? "10px" : "14px"}
                  fontFamily="Poppins"
                  fontWeight="400"
                  >
                    +91
                  </Text>
                </InputLeftElement>
              <Input
                name="mobile"
                maxH={ isMobile ? "25.562px" : "48px"}
                value={userData.mobile}
                onChange={handleChange}
                type="tel"
                bg="white"
                borderColor="gray.300"
                pattern="[0-9]{10}" 
                title="Please enter a 10-digit mobile number"
                required 
                pl={ isMobile ? "51px":"64px"}
                fontSize={isMobile ? "10px" : "14px"}
                fontFamily="Poppins"
                fontWeight="400"
              />
            </InputGroup>
            </Box>
            <Text
              mb={isMobile ? "3" : "5"}
              color="#919598"
              fontSize={isMobile ? "5px" : "10px"}
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight={isMobile ? "8px" : "16px"} /* 160% of 10px */
              fontStyle="normal"
            >
              One time password (OTP) will be sent.
            </Text>
            <Button
              maxH={isMobile ? "25.562" : "48px"}
              fontSize={isMobile ? "8.521px" : "16px"}
              fontWeight={"500px"}
              isLoading={loading}
              loadingText={"Loading.."}
              onClick={handleSubmit}
              width="full"
              bg="#01A7B5"
              color="white"
              _hover={{ bg: "teal.600" }}
            >
              Send OTP
            </Button>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Registration;
