import {
    Box,
    Text,
    Button,
    List,
    ListItem,
    ListIcon,
    Icon,
    Flex,
    Image,
    useMediaQuery,
    UnorderedList,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../GlobalComponents/Navbar/Navbar";

const CustomCheckIcon = (props: any) => (
    <Icon viewBox="0 0 19 15" {...props}>
        <path
            width={"50.432px"}
            d="M18.4513 3.97426L14.1353 8.29094L10.035 12.3913L8.72551 13.7008C8.16398 14.2617 7.04212 14.2617 6.48118 13.7008L0.870055 8.08963C0.507449 7.72702 0.379014 7.13019 0.485346 6.60271C0.542694 6.31298 0.67113 6.04363 0.870055 5.8447L2.1795 4.53586C2.73983 3.97432 3.8623 3.97432 4.42383 4.53586L7.60365 7.71561L11.9693 3.34941L13.9418 1.37635L14.8976 0.421148C15.4591 -0.140383 16.5816 -0.140383 17.1419 0.421148L18.4513 1.72999C18.6043 1.88346 18.716 2.07826 18.7853 2.29206C18.9711 2.86196 18.8594 3.56626 18.4513 3.97426Z"
            fill="#2A94F4"
        />
    </Icon>
);

const B2CMembership = ({ setB2CRegisterStatus }: any) => {
    let navigate = useNavigate();
    const [isMobile] = useMediaQuery("(max-width: 480px)");
    const [isTab] = useMediaQuery("(max-width: 768px)");

    const onClickRegister = () => {
        return navigate("/registration");
    };

    return (
        <>
            <Box position={"relative"} bg="linear-gradient(180deg, #FFFFFF 0%, #D2F0F2 100%)" minHeight={"100vh"} overflow={"hidden"}>
                <Navbar />
                <Flex
                position={"relative"}
                flexDirection={(isMobile || isTab) ? "column" : "row"}
                justifyContent={"center"}
                gap={isMobile ? "32px":"50px"}
                alignItems="center"
                marginTop={isMobile? "80px" :"121px"}
                minHeight={isMobile ? "auto" : "calc(100vh-131px)"}
                marginLeft={isMobile ? "5px" :isTab ? "10px": "20px"}
                marginRight={isMobile ? "5px" : isTab ? "10px" : "20px"}
                zIndex={1}
            >
                {/* Left Section */}
                <Box position={"relative"}>
                    <Image
                        width={isMobile ? "30%" : "60%"}
                        src="https://developmentbucket.blob.core.windows.net/logos/poco-care-text-logo.png"
                    />
                    <Box marginTop={isMobile ?"10px":"20px"} paddingLeft={isMobile ? "10px" : "20px"} width={isMobile? "300": "380px"}>
                        <Text
                            color="#333333"
                            fontSize={isMobile ? "14px" : "20px"}
                            fontFamily="Poppins"
                            fontWeight="500"
                            lineHeight={isMobile ? "14px" : "36px"}
                            letterSpacing="0.06px"
                            wordBreak="break-word"
                        >
                            Last chance! 7 days left to get 10% off membership.
                        </Text>
                    </Box>
                </Box>
                {/* Right Section */}
                <Box
                    top={isMobile ? "0px" : "-50px"}
                    width={isMobile ? "303.988px" : isTab ? "auto" : "742px"}
                    bg="white"
                    border="1px solid rgba(0, 0, 0, 0.10)"
                    borderRadius={"4px"}
                    p={isMobile ? "15.32px 10.35px" : "24px 28px"}
                    boxShadow="sm"
                >
                        <Text
                        color="#333"
                        fontFamily="Poppins"
                        fontSize={isMobile ? "14.229px" : "22px"}
                        fontWeight="500"
                        lineHeight="36px"
                        letterSpacing="0.06px"
                        mb= {isMobile ? "4" : "5"}
                    >
                        Membership
                    </Text>
                    <List spacing={3}>
                            <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                                <ListIcon
                                        as={CustomCheckIcon}
                                        height={isMobile ? "9.32px":"15.3px"}
                                        width={isMobile ? "12.2px":"14.97px"}
                                        color="teal.500"
                                        background={'#CCE9FF'}
                                        marginRight={isMobile ? "5px":"25px"}
                                />
                                <Text fontFamily="Poppins" fontSize={isMobile ? "12px" : "14px"} lineHeight={"28px"} fontWeight={500} letterSpacing={"0.06px"}>
                                        Readiness: Building Your Safety Net  
                                </Text>    
                            </ListItem>
                            <UnorderedList
                                    marginLeft={isMobile ? "17px":"55px"}
                                    fontSize={isMobile ? "8px":"10px"}
                                    letterSpacing={"0.06em"}
                                    fontWeight={"400"}
                                    spacing={2}
                                >
                                <ListItem fontFamily="Poppins">Personalized Emergency Response Plan: Customized plan based on medical history, location, and family needs.</ListItem>
                                <ListItem fontFamily="Poppins">24/7 Access to Medical Experts: Consult with healthcare professionals anytime for guidance or reassurance.</ListItem>
                                <ListItem fontFamily="Poppins">Family Member Inclusion: Option to add family members to the plan, ensuring everyone is prepared.</ListItem>
                                <ListItem fontFamily="Poppins">Emergency Contact Management: Update and manage emergency contacts within the system. </ListItem>
                            </UnorderedList>    
                            
                        <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                            <ListIcon
                                as={CustomCheckIcon}
                                height={isMobile ? "9.32px":"15.3px"}
                                width={isMobile ? "12.2px":"14.97px"}
                                color="teal.500"
                                background={'#CCE9FF'}
                                marginRight={isMobile ? "5px":"25px"}
                            />
                            <Text fontFamily="Poppins" fontSize={isMobile ? "12px" : "14px"} lineHeight={"28px"} fontWeight={500} letterSpacing={"0.06px"}>
                                Personalization: Tailored to Your Needs 
                            </Text>
                        </ListItem>
                        <UnorderedList
                            marginLeft={isMobile ? "17px":"55px"}
                            fontSize={isMobile ? "8px":"10px"}
                            letterSpacing={"0.06em"}
                            fontWeight={"400"}
                            spacing={2}
                        >
                            <ListItem fontFamily="Poppins">Ongoing Medical Profile Updates: Keep medical information current for the most effective response. </ListItem>
                            <ListItem fontFamily="Poppins">Location-Based Services: System adapts to the member's current location for the fastest possible assistance.</ListItem>
                            <ListItem fontFamily="Poppins">Preference-Driven Communication: Members choose their preferred method of contact in an emergency. </ListItem>
                        </UnorderedList>
                        <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                            <ListIcon
                                as={CustomCheckIcon}
                                height={isMobile ? "9.32px":"15.3px"}
                                width={isMobile ? "12.2px":"14.97px"}
                                color="teal.500"
                                background={'#CCE9FF'}
                                marginRight={isMobile ? "5px":"25px"}  
                            />
                            <Text fontFamily="Poppins" fontSize={isMobile ? "12px" : "14px"} lineHeight={"28px"} fontWeight={500} letterSpacing={"0.06px"}>
                                Response: When Every Second Counts 
                            </Text>
                        </ListItem>
                        <UnorderedList
                           marginLeft={isMobile ? "17px":"55px"}
                           fontSize={isMobile ? "8px":"10px"}
                           letterSpacing={"0.06em"}
                           fontWeight={"400"}
                           spacing={2}
                        >
                                <ListItem fontFamily="Poppins">Immediate Emergency Assistance: Rapid response time and direct connection to emergency services. </ListItem>
                                <ListItem fontFamily="Poppins">Real-Time Tracking & Coordination: System tracks the emergency and coordinates with relevant parties (hospitals, family, etc.). </ListItem>
                                <ListItem fontFamily="Poppins">Hospital Admission Facilitation: Assistance provided in securing hospital admission and ensuring a smooth transition.</ListItem>
                        </UnorderedList>
                    </List>

                    <Box mt={isMobile ? 5 : 4} mb={isMobile ? "15px":"29px"} height={"1px"} bgColor={"rgba(0, 0, 0, 0.10)"}></Box>

                    <Button
                        onClick={onClickRegister}
                        p={"6px"}
                        fontFamily="Poppins"
                        fontSize={isMobile ? "11.64px" : "18px"}
                        lineHeight={isMobile ? "23.28px":"36px"}
                        fontWeight={"500"}
                        width="full"
                        letterSpacing={"0.06em"}
                        backgroundColor="#01A7B5"
                        color="white"
                        mb={isMobile ? "8px":"12px"}
                        _hover={{ bg: "teal.600" }}
                    >
                        Register
                    </Button>
                </Box>
                </Flex>
                {/* absolute images */}
                <Box position={isMobile ? "static":"absolute"} bottom={0} left={0} zIndex={0}>
                    <Image
                        width={isMobile ? "100%" : isTab ? "400px" : "552.21px"}
                        src="https://developmentbucket.blob.core.windows.net/logos/group_b.png.png"
                    />
                </Box>
                <Box position={"absolute"} top={90} right={5} zIndex={0}>
                    <Image
                        width={isMobile ? "100px" : isTab ? "140px" : "216px"}
                        src="https://developmentbucket.blob.core.windows.net/logos/group_a.png.png"
                    />
                </Box>
            </Box>
        </>
    );
};

export default B2CMembership;
